import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/'
import { 
        SliderField
} from '../../components/library/entitysync'; 


function inputText() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <SliderField 
                    field="input_slider_two" 
                    label="Select a level"
                    mood
                />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputText
